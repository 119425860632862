@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");
body {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
    color: #333;
}

a{
    text-decoration: none;
}

.btn{
    font-size: 0.9rem;
}

.form-control{
    font-size: 0.9rem;
}

.form-select {
    font-size: 0.9rem;
}
.section-padding {
    padding: 100px 0px;
}

h1 {
    font-size: 1.625rem;
    font-weight: 700;
    color: #000;
    text-transform: none;
    margin-bottom: 1rem;
}

.form-wrapper {
    width: 100%;
    max-width: 400px;
    padding: 25px;
    margin: 0 auto;
    border: 1px solid #c7c7c7;
    border-radius: 5px;
}

.sidebar-section {
    box-shadow: inset -1px 0 0 rgb(0 0 0 / 10%);
    background-color: #f8f9fa;
}

    .sidebar-section .nav-link {
        color: #333;
    }

        .sidebar-section .nav-link.active {
            color: #007bff;
        }

.toaster-section-success{
        position: absolute;
        top: 2%;
        right: 2%;
        background-color: #198754;
        box-shadow: 2px 2px 10px 0px #198754;
        padding: 12px 30px;
        margin: 0;
        border-radius: 50px;
        font-size: 14px;
        color: #fff;
}
.toaster-section-success .check-btn{    
    font-size: 16px;
    color: #fff;
    padding-right: 8px;
}
.toaster-section-success .close-btn{
    font-size: 16px;
    color: #fff;
    padding-left: 20px;
    cursor: pointer;
}

.toaster-section-danger{
    position: absolute;
    top: 2%;
    right: 2%;
    background-color: #dc3545;
    box-shadow: 2px 2px 10px 0px #dc3545;
    padding: 12px 30px;
    margin: 0;
    border-radius: 50px;
    font-size: 14px;
    color: #fff;
}
.toaster-section-danger .check-btn{    
font-size: 16px;
color: #fff;
padding-right: 8px;
}
.toaster-section-danger .close-btn{
font-size: 16px;
color: #fff;
padding-left: 20px;
cursor: pointer;
}
.mh-500{
    min-height: 500px;
}
.footer-bg{
    background-color: #f8f9fa;
}

.box-results{
    border: 1px solid #d3d3d3;
    padding: 0.6rem 1rem;
    margin-bottom: 1rem;
}
.box-results-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem 0rem;
}
.box-results-row1{
    display: flex;
    align-items: center;
    padding: 0.3rem 0rem;
}
.box-results-col-lft{
width: 50%;
}

.txt-name{
    font-weight: 600;
    color: #333;

}
.txt-blue{
    color: #0d6efd;
}
.nav .nav-item .nav-link{
    cursor: pointer;
}
.nav .nav-item .nav-link:hover{
    color: #0d6efd;
}
.nav .nav-item ul li{
    list-style: none;
}
.sorting-results{
    text-align: center;
}

.pagination .page-item .page-link{
    cursor: pointer;
}

#loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 50px;
    height: 50px;
    margin: -76px 0 0 -76px;
    border: 7px solid #f3f3f3;
    border-radius: 50%;
    border-top: 7px solid #3498db;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    background-color: rgba(255,255,255,0.7);
    z-index: 9999;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  

  #cover-spin {
    position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color: rgba(255,255,255,0.7);
    z-index:9999;
    display:none;
}

@-webkit-keyframes spin {
	from {-webkit-transform:rotate(0deg);}
	to {-webkit-transform:rotate(360deg);}
}

@keyframes spin {
	from {transform:rotate(0deg);}
	to {transform:rotate(360deg);}
}

#cover-spin::after {
    content:'';
    display:block;
    position:absolute;
    left:48%;top:40%;
    width:40px;height:40px;
    border-style:solid;
    border-color:black;
    border-top-color:transparent;
    border-width: 4px;
    border-radius:50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
}

.attributemapping-header{

}
.attributemapping-heading{
    font-weight: 600;
    margin-bottom: 0.5rem;
}
.attributemapping-body{
margin-bottom: 0.5rem;
}

.delete-btn{
    font-size: 1rem;
    color: #dc3545;
    cursor: pointer;
}
  
  .button {
    font-size: 1em;
    padding: 10px;
    color: #fff;
    border: 2px solid #06D85F;
    border-radius: 20px/50px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease-out;
  }
  .button:hover {
    background: #06D85F;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: visible;
    opacity: 1;
  }
  .overlay:target {
    visibility: visible;
    opacity: 1;
  }
  
  .popup {
    margin: 70px auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 30%;
    position: relative;
    transition: all 5s ease-in-out;
  }
  
  .popup h4 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-weight: 700;
    color: #333;
  }
  .popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
    cursor: pointer;
  }
  .popup .close:hover {
    color: #06D85F;
  }
  .popup .content {
    max-height: 30%;
    overflow: auto;
  }
  
  @media screen and (max-width: 700px){
    .box{
      width: 70%;
    }
    .popup{
      width: 70%;
    }
  }